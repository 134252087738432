import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { catchError, map, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { API_PATH } from '../utils/constants/api.constants';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  /**
   * Name of the cookie received from the server on login.
   */
  COOKIE_NAME = 'jwt';

  localJwtToken = '';

  constructor(
    @Inject(DOCUMENT) public document: Document,
    public cookieService: CookieService,
    public httpClient: HttpClient,
    public router: Router,
    public commonService:CommonService
  ) { }

  getToken() {
    return this.localJwtToken;
  }

  authenticate() {
    return this.httpClient.get(`${this.getAPIBaseURL()}/auth/authenticate`)
      .pipe(
        catchError(() => of({ authenticated: false })),
        map((res: any) => {
          if(!res?.authenticated){
            this.logout();
          }
          return !!res?.authenticated
        } 
      ))
  }

  refreshToken() {
    // `withCredentials` to add the refresh token cookie to the header.
    return this.httpClient.get(`${this.getAPIBaseURL()}/auth/refresh-token`, { withCredentials: true })
      .pipe(
        catchError(() => of({ authenticated: false })),
        map((res: any) => {
          const authenticated = !!res?.authenticated

          if (authenticated && res.token) {
            this.localJwtToken = res.token
          }
          return this.commonService?.isFranchisee || authenticated;
        }),
      )
  }

  redirectToLogin() {
    this.localJwtToken = '';
    this.router.navigate(['/auth/login'])
  }

  logout() {
    // `withCredentials` to set the refresh token cookie to the header, in order to be removed.
    this.httpClient.get(`${this.getAPIBaseURL()}/auth/logout`, { withCredentials: true }).subscribe(()=>{
      sessionStorage.clear()
      this.redirectToLogin()
    })
  }

  logoutAllDevices() {
    this.httpClient.get(`${this.getAPIBaseURL()}/auth/invalidate-refresh-tokens`).subscribe()
    this.redirectToLogin()
  }

  getAPIBaseURL() {
    const localhostApi = 'http://localhost:3300'
    const origin = this.document.location.origin
    return isDevMode() ? localhostApi : origin
  }

  getUserDetail(request){
    const url = environment.BASE_URL + API_PATH.API_BASE_PATH + API_PATH.TOKEN_VERIFY_DATA;
    return this.httpClient.get<any>(url, {
      params: {
       ...request
      }
    });
  }
}
