import { Component, Inject, isDevMode } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from './services/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'control-customer-portal-ui';

  testData: any;

  constructor(
    @Inject(DOCUMENT) public document: Document,
    public httpClient: HttpClient,
    private route:ActivatedRoute,
    private router:Router,
    private commonService:CommonService
  ) {
    this.route.queryParams.subscribe(qParams=>{      
      if(qParams?.customer_id) {
        this.commonService.isFranchisee=true;
        this.commonService.franchiseeCustomerId=qParams?.customer_id;
        this.commonService.franchiseeCustomerContactId=qParams?.customer_contact_id;
        this.commonService.franchiseeProjectid=qParams?.project_id;
        this.commonService.business=qParams?.business;
        this.router.navigate(['/projects'])
      }
    })
  }

  async requestTest() {
    // const localhost = 'http://localhost:3300'
    // const origin = this.document.location.origin
    // const baseUrl = isDevMode() ? localhost : origin

    // const data = await firstValueFrom(this.httpClient.get(`${baseUrl}/api/test`))
    // this.testData = data;
  }
}
