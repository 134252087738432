import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  isFranchisee:boolean=false;
  franchiseeCustomerId:string=null;
  franchiseeCustomerContactId:string=null;
  franchiseeProjectid:string=null;
  business:string=null;
  constructor(
  ) { }
}
