import { MatSnackBarConfig } from '@angular/material/snack-bar';

export interface ISnackbarOptions extends MatSnackBarConfig {
  /**
   * Parameters for interpolated translated message
   */
  translate?: { [key: string]: string };
  /**
   * Change the text for the dismiss action; Translated
   */
  dismiss?: string;
}

/**
 * Default dismiss action text
 */
const DEFAULT_DISMISS = 'Dismiss';

/**
 * Default snackbar timeout
 */
const DEFAULT_DURATION = 4000;

export const SnackbarConstants = Object.freeze({
  DEFAULT_DISMISS :'Dismiss',
  DEFAULT_DURATION,
});
