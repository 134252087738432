export const API_PATH: any = {
   'API_BASE_PATH': '',
   'TOKEN_VERIFY_DATA': 'auth/token-verify-data',
   'AUTH': 'auth',
   'LOGIN': '/login',
   'API_VERSION': 'api/v13/',
   'GOOGLE_LOGIN': '/google-login',
   'GOOGLE_REGISTER': '/google-register',
   'REGISTER': '/register',
   'CONTRACT_LIST': 'api/v13/customer/contract-list',
   'GET_CONTRACTOR_QUOTE_VIEW': 'api/v13/customer/get-quote-view',
   'APPROVE_CONTRACT': 'customer/approve-contract',
   'CUSTOMER_PROJECT_LIST': 'customer/project-list',
   'CHANGE_PASSWORD': '/change-password',
   'PROJECT_DETAIL': 'customer/project-details',
   'LOGOUT': '/logout',
   //FORGET PASSWORD
   'GENERATE_OTP_FORGET_PASS': '/forget-password',
   'VERIFY_OTP_FORGET_PASS': '/verify-forget-otp',
   'CREATE_NEW_PASS': '/reset-password',
   'GET_DAILY_LOGS': 'customer/get-daily-logs',
   'VIEW_DAILY_LOGS': 'customer/view-daily-logs',
   'CUSTOMER_FILE_UPLOAD': 'customer/file-upload',
   // 'CUSTOMER_DAILY_LOG': 'customer/daily-log',
   'CUSTOMER_DAILY_LOG': 'customer/daily-log-comment',

   'GET_INVOICE_LIST': 'customer/invoices/get-invoice',
   'GET_INVOICE_QUOTE_VIEW': 'customer/invoices/view-invoice',

   'GET_FILES': 'customer/get-customer-portals-files',
   'GET_DOCUMENT_SAMPLES': 'customer/document-samples',
   'GET_VARIATION_LIST': 'customer/get-variation',
   'SAVE_VARIATION': 'customer/customer-variation-save',

   'GET_VARIATION_DTLS': 'customer/get-variation-details',
   'DELETE_VARIATION_FILE': 'customer/delete-file',
   'ACCEPT_REJECT_VARIATION': 'customer/accept-reject-variation',
   'GET_OUR_PROCESS': 'customer/get-our-process',
   'GET_SCHEDULE_DETAILS': 'customer/gantt-details',
   'GENERATE_SALT': '/generate-salt',
   'GET_OTP_SALT': '/get-salt-otp',
   'CLEAR_SESSION': 'customer/clear-session',
}
