import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse,
  HttpStatusCode,
  HttpEvent
} from '@angular/common/http';
import { catchError, EMPTY, Observable, switchMap } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    public authenticationService: AuthenticationService
  ) { }

  addCredentialsToRequest(request: HttpRequest<unknown>) {
    // let user = JSON.parse(sessionStorage.getItem('userdata'));
    // let token = sessionStorage.getItem('token')
    //         if(token && user.subcontractor_id) {
    //             request = request.clone({
    //                 setHeaders: {
    //                      'Authorization': `Bearer ${token}`
    //                 }
    //              });
    //         }
    // return next.handle(request);

    return request.clone({
      // withCredentials: true,
      setHeaders: {
        'Authorization': `Bearer ${this.authenticationService.getToken()}`
      }
    });
  }

  // https://stackoverflow.com/a/54328099
  // https://stackoverflow.com/a/72965154

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(this.addCredentialsToRequest(request))
      .pipe(
        catchError((error) => {
          // Instance of an HttpErrorResponse object.
          const isRefreshTokenRoute = request.url.includes('/auth/refresh-token')
          if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.Unauthorized && !isRefreshTokenRoute) {
            return this.handleUnauthorized(request, next)
          }

          // Different error, re-throw.
          throw error
        })
      )
  }

  handleUnauthorized(request: HttpRequest<any>, next: HttpHandler) {
    return this.authenticationService.refreshToken()
      .pipe(
        catchError(_err => EMPTY),
        switchMap(authenticated => {
          if (authenticated) {
            return next.handle(this.addCredentialsToRequest(request))
          }
          this.authenticationService.logout()
          return EMPTY;
        }),

      )
  }
}
